import React from "react";
import {useTranslation} from "react-i18next";
import { Link } from 'gatsby-plugin-react-i18next';
import {Section} from "../common/section";
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"

export const UvcTechnology: React.FC = () => {
  const {t} = useTranslation();

  const promoImage = graphql`
  fragment promoImage on File {
    childImageSharp {
      fluid(fit: INSIDE) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }`

  const data = useStaticQuery(graphql`
    query {
      uvcTechnologyImage: file(relativePath: {eq: "home/uvc-technology.png"}) { ...promoImage }
    }
  `)

  return (
    <Section className="uvc-technology">

    <div className="text">
      <p className="subheading">{t('home.uvc-technology.subheading')}</p>
      <h2>{t('home.uvc-technology.heading')}</h2>

      <h4>{t('home.uvc-technology.small-heading-01')}</h4>
      <p className="small">{t('home.uvc-technology.content-01')}</p>

      <h4>{t('home.uvc-technology.small-heading-02')}</h4>
      <p className="small">{t('home.uvc-technology.content-02')}</p>

      <h4>{t('home.uvc-technology.small-heading-03')}</h4>
      <p className="small">{t('home.uvc-technology.content-03')}</p>

      <Link className="button" to="/technology">{t('home.uvc-technology.button')}</Link>

    </div>

    <div className="image">
      <GatsbyImage
        
        fluid={data.uvcTechnologyImage.childImageSharp.fluid}
      />
    </div>
    </Section>
  )
}
