import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../common/section";

export const HowItWorks: React.FC = () => {
  const {t} = useTranslation();
  const items = [1,2,3,4];
  return (
      <Section className="how-it-works" id="how-it-works">
         <h2>{t('home.how-it-works.heading')}</h2>
          <p>{t('home.how-it-works.content-01')}</p>
          <p>{t('home.how-it-works.content-02')}</p>

        <div className="details-list">
          {
            items.map((item, index) => {
              return (
                  <div key={index}>
                    <h1>{index+1}</h1>
                    <h3>{t(`home.how-it-works.items.${index}.heading`)}</h3>
                   
                    <p className="small">{t(`home.how-it-works.items.${index}.description`)}</p>
                    {
                      index === 3 && (
                        <a href="/disinfection_report.pdf" download>{t(`home.how-it-works.items.${index}.link`)}</a>
                      )
                    }
                  </div>
              )
            })
          }
        </div>
      </Section>
  )
}
