import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { Section } from '../common/section';
import { Video } from '../common/video';

export const Solutions: React.FC = () => {
  const {t} = useTranslation();

  return (
      <Section className="solutions">
        <h2>{t('home.solutions.heading')}</h2>

        <Video
          videoTitle="UVX.solutions"
          videoSrcURL="https://www.youtube.com/embed/2AGfA8oMeHs"
        />

        <ul className="products-list">
          <li className="daily">
            <h3 className="list-title">{t('home.solutions.items.0.heading')}</h3>
            <p>{t('home.solutions.items.0.description')}</p>
            <Link className="button" to="/contact">{t('home.solutions.items.0.button')}</Link>
          </li>
          <li className="weekly">
            <h3 className="list-title">{t('home.solutions.items.1.heading')}</h3>
            <p>{t('home.solutions.items.1.description')}</p>
            <Link className="button" to="/contact">{t('home.solutions.items.1.button')}</Link>
          </li>
          <li className="one-off">
            <h3 className="list-title">{t('home.solutions.items.2.heading')}</h3>
            <p>{t('home.solutions.items.2.description')}</p>
            <Link className="button" to="/contact">{t('home.solutions.items.2.button')}</Link>
          </li>
        </ul>
      </Section>
  )
}
