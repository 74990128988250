import React from "react"
import {Introduction} from "../components/home/introduction";
import {Solutions} from "../components/home/solutions";
import {Applications} from "../components/home/applications";
import {Services} from "../components/home/services";
import {NormalLayout} from "../components/layouts/normal_layout";
import {SEO} from "../components/seo";
import {Callout} from "../components/home/callout";
import { Accreditations } from "../components/home/accreditations"
import { HowItWorks } from "../components/home/how-it-works"
import { UvcTechnology } from "../components/home/uvc-technology"

const Home: React.FC = () => {
  return (
      <NormalLayout type="home">
        <SEO title="Home | UVX Solutions" description="Home | UVX Solutions"/>
        <Introduction/>
        <Callout />
        <Solutions/>
        <Accreditations />
        <UvcTechnology />
        <Applications/>
        <HowItWorks />
        <Services/>
      </NormalLayout>
  );
}

export default Home;